/* .dropdown-menu {
  list-style: none;
  position: absolute;
  left: 98px;
  text-align: start;
  top: 5.1rem;
  width: auto;
  z-index: 1;
} */
/* .dropdown-menu2 {
  list-style: none;
  position: absolute;
  left: 275px;
  text-align: start;
  top: 5.1rem;
  width: auto;
  z-index: 1;
} */
.dropdown-menu {
  width: 11.5rem;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}
.dropdown-menu2 {
  width: 8.8rem;
  position: absolute;
  top: 5rem;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  font-size: 5px;
  background: #9b2c2c;
  cursor: pointer;
  padding-left: 0.4rem;
}

.dropdown-menu li:hover {
  background: #c53030;
}
.dropdown-menu2 li {
  font-size: 5px;
  background: #9b2c2c;
  cursor: pointer;
  padding-left: 0.5rem;
}

.dropdown-menu2 li:hover {
  background: #c53030;
}



.dropdown-link {
  color: #fff;
  display: block;
  font-size: 0.8rem;
  height: 100%;
  width: 100%;
  padding: 0.6rem;
  text-decoration: none;
}

