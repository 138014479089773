@media (min-width: 340px) {
    
    .header-title {
        font-size: 4rem; 
    }
  }
@media (min-width: 640px) {
    
    .header-title {
        font-size: 4.8rem; 
    }
  }
@media (min-width: 768px) {
    
    .header-title {
        font-size: 6rem; 
    }
  }
@media (min-width: 1024px) {
    
    .header-title {
        font-size: 7.6rem; 
    }
  }

