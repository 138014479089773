.imgslider  {
    margin-top: 15px;
    margin-right: 0;
}
  
.slick-slide img {
    margin: auto;
    padding: 0;
    width: 200px;
    height: auto;
}
/* width: 70%*/
